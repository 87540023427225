import { RedDotListData, TmapApp } from '@tmap-web-lib/tmap-app-interface'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai/index'
import { debounce } from 'lodash-es'
import Image from 'next/image'
import process from 'process'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { gte, lte } from 'semver'

import BizBanner from '@/assets/images/img_my_menu_biz.svg'
import { bizPlaceBannerAtom, remoteConfigServiceListAtom } from '@/atoms'
import { sendEventLog } from '@/features/log/log.fn'
import MyLogDefinition from '@/features/log/log.my'
import { useOpenService, useOpenServiceUrl } from '@/hooks'
import { BizPlaceBannerType, GroupListType, ServiceListType, envServiceUrl } from '@/utils'

import * as styles from './serviceList.css'

interface Props {
  redDotList: Pick<RedDotListData, 'itemGroup' | 'item'>[]
}

function ServiceList(props: Props) {
  const { redDotList } = props
  const remoteConfigServiceList = useAtomValue(remoteConfigServiceListAtom)
  const bizPlaceBanner = useAtomValue(bizPlaceBannerAtom)
  const openService = useOpenService()
  const openServiceUrl = useOpenServiceUrl()
  const { action_id } = MyLogDefinition.my
  const env = process.env.NEXT_PUBLIC_TARGET
  const [serviceList, setServiceList] = useState<ServiceListType[] | null>(null)
  const [bizPlaceStatus, setBizPlaceStatus] = useState<BizPlaceBannerType | null>(null)

  const os = TmapApp.env.isAndroid ? 'AOS' : 'iOS'

  const callClickDebouncing = useMemo(
    () =>
      debounce(
        (serviceUrl: string, actionLog: string, itemGroup?: string, itemName?: string) => {
          // 로그 전송 및 레드닷 업데이트
          if (itemGroup && itemName) {
            sendEventLog(actionLog, { unit: 'REDDOT' })
            if (
              itemGroup !== 'COMMUNICATION' &&
              itemGroup !== 'HOME' &&
              itemGroup !== 'BOARD' &&
              !itemName.includes('BOARD-')
            ) {
              TmapApp.updateRedDotList({
                updateData: [
                  {
                    itemGroup: itemGroup,
                    item: itemName,
                    displayType: 'REDDOT',
                    readDateTime: dayjs().format('YYYYMMDDHHmmss'),
                  },
                ],
              })
              TmapApp.updateAllRedDots()
            }
          } else {
            sendEventLog(actionLog)
          }

          // 서비스 이동 로직
          if (serviceUrl === 'payment') {
            TmapApp.startPaymentActivity({ url: '/' })
          } else if (serviceUrl === 'tmap://cs') {
            if (TmapApp.env.isAndroid) {
              // AOS에서 고객센터는 이미 가로모드 미지원
              openService(serviceUrl)
            } else {
              openService(serviceUrl, { portraitonly: 'true' })
            }
          } else if (serviceUrl.startsWith('tmap://')) {
            openService(serviceUrl)
          } else {
            if (serviceUrl.startsWith('https://')) {
              openServiceUrl(serviceUrl, true)
            } else {
              openServiceUrl(serviceUrl, true, true)
            }
          }
        },
        1000,
        { leading: true, trailing: false }
      ),
    [openService, openServiceUrl]
  )

  const handleClick = useCallback(
    (serviceUrl: string, actionLog: string, itemGroup?: string, itemName?: string) => {
      callClickDebouncing(serviceUrl, actionLog, itemGroup, itemName)
    },
    [callClickDebouncing]
  )

  const callTmapBizInfoDebouncing = useMemo(
    () =>
      debounce(
        (actionLog: string) => {
          sendEventLog(actionLog)
          switch (env) {
            case 'dev':
              openServiceUrl(envServiceUrl.tmapBizInfo.dev, true)
              break
            case 'dtg':
              openServiceUrl(envServiceUrl.tmapBizInfo.stg, true)
              break
            case 'stg':
              openServiceUrl(envServiceUrl.tmapBizInfo.stg, true)
              break
            case 'rtg':
              openServiceUrl(envServiceUrl.tmapBizInfo.prd, true)
              break
            case 'prd':
              openServiceUrl(envServiceUrl.tmapBizInfo.prd, true)
              break
          }
        },
        1000,
        { leading: true, trailing: false }
      ),
    [env, openServiceUrl]
  )

  const handleClickTmapBizInfo = useCallback(
    (actionLog: string) => {
      callTmapBizInfoDebouncing(actionLog)
    },
    [callTmapBizInfoDebouncing]
  )

  const getServiceListMenu = useCallback(
    (service: GroupListType) => {
      const isRedDot = redDotList.find((redDot) => {
        if (
          (redDot.item.startsWith('EVENT') || redDot.item.startsWith('NOTICE')) &&
          service.redDotItem === 'COMMUNICATION'
        ) {
          return true
        } else {
          if (service.url.startsWith('tmap://board')) {
            const boardid = service.url.split('boardid=')[1]
            if (redDot.item.includes(boardid)) {
              return true
            }
          } else if (
            redDot.item === service.redDotItem ||
            redDot.itemGroup === service.redDotItem
          ) {
            return true
          }
        }
      })
      return (
        <button
          data-testid="service_list_name"
          key={service.name}
          className={`${styles.serviceBtn}`}
          onClick={() => {
            if (service.log_action_id) {
              if (isRedDot) {
                handleClick(service.url, service.log_action_id, isRedDot.itemGroup, isRedDot.item)
              } else {
                handleClick(service.url, service.log_action_id)
              }
            }
          }}
        >
          <div
            data-testid={isRedDot && 'service_list_reddot'}
            className={`${styles.reddotWrapper}  ${isRedDot ? styles.redDotVariants.reddot : ''}`}
          >
            {service.name}
          </div>
        </button>
      )
    },
    [handleClick, redDotList]
  )

  useEffect(() => {
    if (remoteConfigServiceList.length) {
      setServiceList(JSON.parse(remoteConfigServiceList))
    }
  }, [remoteConfigServiceList])

  useEffect(() => {
    if (bizPlaceBanner.length) {
      setBizPlaceStatus(JSON.parse(bizPlaceBanner))
    }
  }, [bizPlaceBanner])

  return (
    <div className={styles.wrapper}>
      {serviceList?.map((group: ServiceListType) => {
        return (
          <div key={group.groupCode}>
            <p
              data-testid="service_list_group_name"
              className={styles.groupTitle}
            >
              {group.groupName}
            </p>
            {group.groupList.map((service) => {
              if (!service.osType || service.osType?.includes(os)) {
                if (
                  (!service.minVersion || gte(TmapApp.env.appVersion, service.minVersion)) &&
                  (!service.maxVersion || lte(TmapApp.env.appVersion, service.maxVersion))
                ) {
                  return getServiceListMenu(service)
                }
              }
            })}
          </div>
        )
      })}
      {bizPlaceStatus?.isShow && (
        <button
          data-testid="service_list_biz_banner"
          className={styles.bizPlaceBannerWrapper}
          onClick={() =>
            action_id?.tapBizPlaceBanner &&
            handleClick(bizPlaceStatus.url, action_id.tapBizPlaceBanner)
          }
        >
          <div>
            <p className={styles.bizPlaceTitle}>비즈 플레이스</p>
            <p>티맵에 업체 등록하고 관리하기</p>
          </div>
          <Image
            src={BizBanner}
            alt={''}
          />
        </button>
      )}

      <button
        data-testid="tmap_biz_info"
        className={styles.tmapBizInfo}
        onClick={() =>
          action_id?.tapBusinessInfo && handleClickTmapBizInfo(action_id.tapBusinessInfo)
        }
      >
        티맵모빌리티(주) 사업자정보
      </button>
    </div>
  )
}

export { ServiceList }
