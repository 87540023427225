import { UseQueryResult } from '@tanstack/react-query'
import {
  PointsSummaryResponse,
  getTmapPayPointsSummary,
} from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai/index'

import { mockHost } from '@/apis'
import { storageMockEnabled } from '@/atoms'
import { useQueryWrapper } from '@/hooks/useQueryWrapper'
import { KEYS } from '@/react-query'

interface Props {
  useErrorBoundary?: boolean
}

function useGetTmapPayPointsSummary(
  props: Props
): UseQueryResult<PointsSummaryResponse, AxiosError> {
  const storageMock = useAtomValue(storageMockEnabled)
  return useQueryWrapper<PointsSummaryResponse, AxiosError>(
    [KEYS.USER_POINT()],
    async () => {
      if (storageMock) {
        const { data } = await mockHost.get<PointsSummaryResponse>('point-api/v2/points/summary')
        return data
      } else {
        const { data } = await getTmapPayPointsSummary()
        return data
      }
    },
    {
      useErrorBoundary: props?.useErrorBoundary ?? true,
    }
  )
}

export { useGetTmapPayPointsSummary }
