import { RedDotListData, TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { formatCommaNumber } from '@tmap-web-lib/utils'
import dayjs from 'dayjs'
import { useSetAtom } from 'jotai'
import { debounce } from 'lodash-es'
import Image from 'next/image'
import { useCallback, useMemo, useState } from 'react'

import { storageTooltipClicked } from '@/atoms'
import Tooltip from '@/components/Tooltip/Tooltip'
import { sendEventLog } from '@/features/log/log.fn'
import MyLogDefinition from '@/features/log/log.my'
import { useOpenService } from '@/hooks'
import { schemeUrl } from '@/utils'

import * as styles from './userProfile.css'

export interface UserProfileProps {
  loginAuthType: string
  image: string | null
  name: string
  mdn: string
  tid: string
  point: number
  isVerified: boolean
}

export interface Props extends UserProfileProps {
  redDotList: RedDotListData[]
}

export function UserProfile(props: Props) {
  const { loginAuthType, image, name, mdn, tid, point, isVerified, redDotList } = props
  const openService = useOpenService()
  const setTooltipClickedStorageValue = useSetAtom(storageTooltipClicked)
  const { action_id } = MyLogDefinition.my
  const [isImageError, setIsImageError] = useState(false)

  const displayNickName = useMemo(() => {
    if (name) {
      return name
    } else {
      if (loginAuthType === 'None') {
        return '닉네임 등록하기'
      } else if (loginAuthType === 'MDC' || loginAuthType === 'MCI') {
        if (mdn.length) {
          return mdn
        }
      } else if (loginAuthType === 'TID') {
        if (tid) {
          return tid
        }
      }
      return '닉네임 등록하기'
    }
  }, [loginAuthType, name, mdn, tid])

  const callMoveToProfileEdit = useMemo(
    () =>
      debounce(
        () => {
          const custom = {
            unit: isVerified !== null && !isVerified ? 'N' : 'Y',
          }
          setTooltipClickedStorageValue(true)
          sendEventLog(action_id?.tapMyinfo, custom)
          openService(schemeUrl.profile)
        },
        1000,
        { leading: true, trailing: false }
      ),
    [openService, isVerified, action_id?.tapMyinfo, setTooltipClickedStorageValue]
  )

  const moveToProfileEdit = useCallback(() => {
    callMoveToProfileEdit()
  }, [callMoveToProfileEdit])

  const callMoveToPointDebouncing = useMemo(
    () =>
      debounce(
        () => {
          if (redDotList.length) {
            TmapApp.updateRedDotList({
              updateData: [
                {
                  itemGroup: 'SERVICE_INDEX',
                  item: 'POINT',
                  displayType: 'REDDOT',
                  readDateTime: dayjs().format('YYYYMMDDHHmmss'),
                },
              ],
            })
            TmapApp.updateAllRedDots()
          }
          sendEventLog(action_id?.tapPoint, redDotList.length ? { unit: 'REDDOT' } : null)
          TmapApp.startPointActivity({ path: '/' })
        },
        1000,
        { leading: true, trailing: false }
      ),
    [redDotList, action_id?.tapPoint]
  )

  const moveToPoint = useCallback(() => {
    callMoveToPointDebouncing()
  }, [callMoveToPointDebouncing])

  const displayPoint = useMemo(() => {
    if (point > 0) {
      return <p>{formatCommaNumber(point)} P</p>
    } else {
      return <p>내 포인트</p>
    }
  }, [point])

  return (
    <div className={styles.wrapper}>
      <Tooltip content={'프로필을 설정하고 나를 표현해보세요!'}>
        <button onClick={moveToProfileEdit}>
          <Image
            width={56}
            height={56}
            style={{ borderRadius: '50%' }}
            src={
              image && !isImageError
                ? image
                : require('../../../../assets/images/profile_s.svg').default
            }
            alt={image ? '사용자 제공 이미지' : '기본 이미지'}
            unoptimized={Boolean(image)}
            priority={true}
            onError={() => setIsImageError(true)}
          />
        </button>

        <div className={styles.userInfoWrapper}>
          <button
            className={styles.nameBtn}
            onClick={moveToProfileEdit}
          >
            <p
              data-testid="profile_name"
              className={`${styles.name} ${(!displayNickName.length || loginAuthType === 'None') && styles.nameUnregistered} ${isVerified ? styles.nameWidthVariants.verified : styles.nameWidthVariants.notVerified}`}
            >
              {displayNickName}
            </p>
            {!isVerified && (
              <div
                className={styles.unVerifiedTag}
                data-testid="profile_verified"
              >
                미인증
              </div>
            )}
            <Image
              src={require('../../../../assets/images/ico_pen_write.svg').default}
              alt=""
            />
          </button>
          <button
            className={redDotList?.length ? styles.userPointRedDot : styles.userPoint}
            onClick={moveToPoint}
          >
            {displayPoint}
          </button>
        </div>
      </Tooltip>
    </div>
  )
}
